<template>
  <b-container fluid="">
    <iq-card>
      <template v-slot:headerAction>
       <b-button @click.prevent="createMeeting({type: 1, data: false})">Crear nueva reunion</b-button>
      </template>
      <template v-slot:body>
        <p class="h5">Reuniones Programadas</p>
        <ag-grid-vue
          id="ag-grid"
          class="ag-theme-material border height-500"
          :columnDefs="headersAllData"
          :rowData="dataMeetings"
          :floatingFilter="true"
          :modules="modules"
          rowSelection="single"
          :resizable="true"
          :paginationPageSize="paginationPageSize"
          :enableCellTextSelection="true"
          :enableSelection="true"
          :pagination="true"
          @rowDoubleClicked="onRowClickedAllProperties"
          style="width: 100%; height: 80vh"
        ></ag-grid-vue>
      </template>
    </iq-card>
    <b-modal v-model="showModalOptions" o-close-on-esc no-close-on-backdrop hide-header-close  >
      <div v-if="initTransaction">
        <loader-process :message="messageInformation"></loader-process>
      </div>
      <div v-else>
        <div class="credentials-meeting">
          <p class="title h6 text-center mt-2">
            CREDENTIALS MEETING
          </p>
          <p class="h6 text-center">
            Topic: {{meetingUpdate.topic}}
          </p>
          <p class="h6 text-center mb">
            Assigned email: {{meetingUpdate.emailAdminMeeting}}
          </p>
          <p class="h6 text-center mb-3">
            Date Init: {{meetingUpdate.startTime}}
          </p>
        </div>
        <b-button block variant="primary"  @click.prevent="startMeeting"> <i class="ri-vidicon-fill"></i> Start meeting</b-button>
        <b-button block variant="secondary" @click.prevent="editMeeting"> <i class="ri-settings-2-line"></i> Edit Meeting Zoom</b-button>
        <b-button block variant="danger"  @click.prevent="deleteMeeting"> <i class="ri-delete-bin-2-fill"></i> Delete Meeting Zoom</b-button>
      </div>
      <template #modal-footer >
        <b-button @click="cancelModalOption">Cancel</b-button>
      </template>
    </b-modal>
    <b-modal v-model="isMeetingActive" o-close-on-esc no-close-on-backdrop hide-header-close >
      <div>
        <p class="h4">
          There is an active meeting
        </p>
        <p class="my-0">Topic: <span>{{meetingActive.topic}}</span></p>
        <p class="my-0"> Description: <span>{{meetingActive.description}}</span></p>
        <p class="my-0">Id meeting : <span>{{meetingActive.idMeeting}}</span></p>
        <p class="my-0">Date time : <span>{{meetingActive.startTime}}</span></p>
      </div>
      <template #modal-footer >
        <b-button @click="managmentMeetingActive(2)">Cancel meet</b-button>
        <b-button @click="managmentMeetingActive(1)">Go to meet</b-button>
      </template>
    </b-modal>
    <modal-meeting :type="type" @showAlertMessage="showAlertMessage"  @changeModal="changeModal" :showModal="showModal" :meetingUpdate="meetingUpdate"></modal-meeting>
  </b-container>
</template>
<script>
import ModalMeeting from '@/components/VideoCall/ModalMeeting'
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '@/config/pluginInit'
import { AgGridVue } from 'ag-grid-vue'
import { db } from '@/config/firebase'
import LoaderProcess from '@/components/core/loader/LoaderProcess.vue'

import axios from 'axios'
// axios.defaults.baseURL = 'http://localhost:5000/app-calendario-1/us-central1/app/v1/api/'
axios.defaults.baseURL = process.env.VUE_APP_URL_BASE_CLOUD_FUNCTIONS_API

export default {
  name: 'VideoCall',
  watch: {
    $route (to, from) {
      console.log(to)
      console.log(from)
    }
  },
  firestore () {
    return {
      dataMeetings: db.collection('campus/Vs2FkUx38u3W8IDuABNF/meetings')
      // dataMeetings: db.collection('campus').where('coPropertyDocId', '==', this.$route.params.id)
    }
  },
  components: {
    AgGridVue,
    ModalMeeting,
    LoaderProcess
  },
  mounted () {
    core.index()
    core.activeRoute()
    this.checkMeetingsOpen()
  },
  data () {
    return {
      showModalOptions: false,
      isMeetingActive: false,
      dataMeetings: null,
      modules: AllCommunityModules,
      paginationPageSize: null,
      headersAllData: null,
      showModal: false,
      type: 1,
      initTransaction: false,
      messageInformation: '',
      meetingUpdate: {},
      meetingActive: {
        coPropertyDocId: '',
        description: '',
        duration: '',
        emailAdminMeeting: '',
        idMeeting: '',
        joinUrl: '',
        onCall: false,
        startTime: '',
        startUrl: '',
        topic: '',
        userDocId: ''
      }
    }
  },
  beforeMount () {
    this.headersAllData = [
      {
        headerName: 'Estado',
        valueGetter: (params) => {
          return params.data.onCall === true ? 'CALL ON' : 'CALL OFF'
        },
        width: 120
      },
      { headerName: 'Fecha Reunion', field: 'start_time', sortable: true, filter: true, resizable: true, sort: 'desc' },
      { headerName: 'Nombre', field: 'topic', sortable: true, filter: true, resizable: true },
      { headerName: 'Usuario de Zoom', field: 'host_email', sortable: true, filter: true, resizable: true },
      { headerName: 'Link de Zoom', field: 'join_url', sortable: true, filter: true, resizable: true, width: 350 }
    ]
    this.autoGroupColumnDef = {
      headerName: 'Names',
      cellRendererParams: { suppressCount: true }
    }
    this.paginationPageSize = 10
  },
  methods: {
    onRowClickedAllProperties (params) {
      this.showModalOptions = true
      this.meetingUpdate = params.data
    },
    editMeeting () {
      this.type = 2
      this.showModalOptions = false
      this.showModal = true
    },
    async deleteMeeting () {
      try {
        this.initTransaction = true
        this.messageInformation = 'Delete meeting'
        const value = await this.$bvModal.msgBoxConfirm('Are you sure of delete this meeting?')
        if (value) {
          const response = await axios.delete(`meetings/${this.meetingUpdate.idMeeting}`, { data: { docIdMeeting: this.meetingUpdate.id } })
          core.showSnackbar('success', response.data.message)
        } else {
          core.showSnackbar('success', 'Process canceled')
        }
        this.showModalOptions = false
        this.meetingUpdate = {}
        this.initTransaction = false
      } catch (error) {
        core.showSnackbar('error', 'Has been ocurred an error')
        this.initTransaction = false
      }
    },
    async startMeeting () {
      let userInfo = null
      const querySnapshot = await db.collection('users-zoom').where('email', '==', this.meetingUpdate.emailAdminMeeting).get()
      querySnapshot.forEach(doc => {
        if (doc.data().license === true && doc.data().available === true) {
          userInfo = doc.data()
          userInfo.id = doc.id
        }
      })
      if (userInfo !== null) {
        await db.collection('users-zoom')
          .doc(userInfo.id)
          .update({
            available: false
          })
        await db.collection('meeting')
          .doc(this.meetingUpdate.id)
          .update({
            onCall: true
          })
        this.$router
          .push({ name: 'dashCoproperty.videoCall.meeting', params: { meetingId: this.meetingUpdate.idMeeting } })
          .catch(() => {
            this.meetingUpdate = {}
          })
        this.showModalOptions = false
      } else {
        this.meetingUpdate = {}
        this.showModalOptions = false
        core.showSnackbar('error', 'Ha ocurrido un error, por favor comuniquese con el area encargada para brindar soporte')
      }
    },
    async checkMeetingsOpen () {
      const querySnapshot1 = await db
        .collection('meeting')
        .where('userDocId', '==', localStorage.getItem('userDocId'))
        .where('onCall', '==', true)
        .get()
      if (!querySnapshot1.empty) {
        querySnapshot1.forEach(doc => {
          this.meetingActive = doc.data()
          this.meetingActive.docId = doc.id
          this.isMeetingActive = true
        })
      }
    },
    async managmentMeetingActive (type) {
      if (type === 1) {
        this.$router
          .push({ name: 'dashCoproperty.videoCall.meeting', params: { meetingId: this.meetingActive.idMeeting } })
          .catch(() => {
            this.meetingUpdate = {}
          })
      } else {
        await db.collection('meeting')
          .doc(this.meetingActive.docId)
          .update({
            onCall: false
          })
        let userZoomId = ''
        const querySnapshot = await db.collection('users-zoom')
          .where('email', '==', this.meetingActive.emailAdminMeeting)
          .get()
        querySnapshot.forEach(doc => {
          userZoomId = doc.id
        })
        await db.collection('users-zoom')
          .doc(userZoomId)
          .update({
            available: true
          })
        this.isMeetingActive = false
      }
    },
    cancelModalOption () {
      this.meetingUpdate = {}
      this.showModalOptions = false
    },
    showAlertMessage (data) {
      this.meetingUpdate = {}
      core.showSnackbar(data.type, data.data)
    },
    changeModal ({ data }) {
      this.meetingUpdate = {}
      this.showModal = !data
    },
    createMeeting ({ type, data }) {
      this.showModal = !data
      this.type = type
    }
  }
}
</script>

<style scoped>
.credentials-meeting{
  border: 1px solid #cecece;
  border-radius: 5px;
  margin-bottom: 10px;
  border-top: 2px solid #ec6608;
  box-shadow: 2px 2px 2px #ffd3b4;
}
</style>
